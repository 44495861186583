import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { useState } from "react";
import { useAuthenticator } from '@aws-amplify/ui-react'; //AmplifyでReactのuiを提供するライブラリ
import { Child2 } from './Child2';
import { signOut } from 'aws-amplify/auth';
import { Child3 } from './Child3';



export const Child1 = () => {

    const [isError, setisError] = useState(false);
    const [isData, setisData] = useState(false);
    const [isConnecting, setisConnecting] = useState(false);
    const [data, setData] = useState([]);
    // let e_mail: string = "";


    //AmplifyでReactのuiを提供するライブラリ
    const { user } = useAuthenticator((context) => [context.user]);

    // if (typeof (user.signInDetails && user.signInDetails["loginId"]) === "string") {
    //     const e_mail = user.signInDetails && user.signInDetails["loginId"];
    // }


    const handleClick = async () => {
        setisData(false)
        setisError(false);
        setisConnecting(true)
        const { idToken } = (await fetchAuthSession()).tokens ?? {}
        try {

            const response = await axios.get("https://5pnkeb5n3c.execute-api.ap-northeast-1.amazonaws.com/dev/pets", { headers: { Authorization: idToken?.toString() ?? '' } });
            console.log(response.data);
            setData(response.data);
            setisConnecting(false)
            setisData(true);
            setisError(false);
        }
        catch (err) {
            console.log(err);
            setisData(false)
            setisConnecting(false);
            setisError(true);
        }
    }

    const deleteClick = async () => {
        //const body_data = { email: user.signInDetails && user.signInDetails["loginId"] };
        const body_data = { "user": user.signInDetails && user.signInDetails["loginId"] }
        const { idToken } = (await fetchAuthSession()).tokens ?? {}
        try {

            const response = await axios.post("https://5pnkeb5n3c.execute-api.ap-northeast-1.amazonaws.com/dev/delete_user", body_data, { headers: { Authorization: idToken?.toString() ?? '' } });
            console.log(response.data);
            signOut();

        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <p>Hello {user.signInDetails && user.signInDetails["loginId"]}</p>
            <button onClick={handleClick} className="px-10">ボタン</button>
            <button onClick={deleteClick} className="px-10">退会</button>
            <h3>{isConnecting && <>通信中</>}</h3>
            <h3>{isError && <>データ取得失敗</>}</h3>
            <h3>{isData && <>{data[0]["price"]}</>}</h3>
            <Child2></Child2>
            <Child3 email={user.signInDetails && user.signInDetails["loginId"]}></Child3>
        </>
    )
}