import React, { FC } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { useEffect, useState } from "react";
import { DataProps } from '../types/type';
import { Child1 } from './Child1';

import { Links } from './Links';

const GetWeatherData: any = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const { idToken } = (await fetchAuthSession()).tokens ?? {}
            const instance = axios.create({
                baseURL: 'https://5pnkeb5n3c.execute-api.ap-northeast-1.amazonaws.com/dev',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': idToken?.toString() ?? ''
                },
                timeout: 2000,
            })
            const response = await instance.get("/pets")
            //const response = await axios.get("https://5pnkeb5n3c.execute-api.ap-northeast-1.amazonaws.com/dev/pets", { headers: { Authorization: idToken?.toString() ?? '' } });
            console.log(response.data)
            setData(response.data);
        };
        fetchData();
    }, []);

    return data;
};




export const Main: FC<DataProps> = (props) => {


    const data = GetWeatherData()

    if (data.length !== 0)
        return (
            <>
                <h1>Hello! {props.user?.username}</h1>
                <h1>{data[0]["price"]}</h1>
                <Child1></Child1>

                <Links></Links>
                <button onClick={props.signOut}>Sign out</button>
            </>
        );
    else
        return (
            <>
                <h1>Loading</h1>
            </>)
}