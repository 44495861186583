
import { BrowserRouter, Link, Routes, Route, Navigate, } from "react-router-dom";
import { Page1 } from "./pages/Page1";
import { Page2 } from "./pages/Page2";
import { Page3 } from "./pages/Page3";

export const Links = () => {
    return (
        <BrowserRouter>
            <div className="App">
                <br />
                <Link to="/page1">Page1</Link>
                <br />
                <Link to="/page2">Page2</Link>
                <br />
                <Link to="/page3">Page3</Link>
                <br />

                <Routes>
                    {/* exactをつけると完全一致になります。Homeはexactをつけてあげます */}
                    <Route path="/" element={<Navigate to='/' />} />
                    <Route path={`/Page1`} element={<Page1 />} />
                    <Route path={`/page2`} element={<Page2 />} />
                    <Route path={`/page3`} element={<Page3 />} />
                </Routes>
            </div>
        </BrowserRouter >
    );
}