import React from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import { Main } from "./components/Main";



Amplify.configure(awsconfig)




const App = () => {

  return (
    <Authenticator>
      {({ signOut, user }) => (
        <main>
          <Main signOut={signOut} user={user}></Main>
        </main>
      )}
    </Authenticator>
  );
}



export default App;
