import { signOut } from 'aws-amplify/auth';


export const Child2 = () => {

    async function handleSignOut() {
        try {
            await signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <>
            <p>Child2</p>
            <button onClick={handleSignOut} className="px-10">自作サインアウト</button>
        </>
    )
}
