import { useState, ChangeEvent } from "react";
import {
    confirmUserAttribute,

} from 'aws-amplify/auth';
import {
    updateUserAttribute

} from 'aws-amplify/auth';



export const Child3 = (props: any) => {

    // type Color = 'red' | 'blue'
    // const color1: Color = 'red' // ok
    // const color2: Color = 'blue' // ok


    const [text, setText] = useState<string>("");
    const [code, setCode] = useState<string>("");

    const onChangeText = (e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)
    const onChangeCode = (e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)



    // async function handleUpdateEmailAttributes(
    // ) {
    //     try {
    //         await updateUserAttributes({
    //             userAttributes: {
    //                 email: text
    //             }
    //         });
    //         // handle next steps
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    async function handleUpdateUserAttribute() {
        const attributeKey = "email"
        const value = text
        try {
            await updateUserAttribute({
                userAttribute: {
                    attributeKey,
                    value
                }
            });

        } catch (error) {
            console.log(error);
        }
    }


    async function handleConfirmUserAttribute() {
        try {
            await confirmUserAttribute({ userAttributeKey: 'email', confirmationCode: code });
        } catch (error) {
            console.log(error);
        }
    }
    // const hoge: ConfirmUserAttributeInput = { userAttributeKey: 'email', confirmationCode: "123456" };

    return (
        <>

            <p>Child3</p>
            <input type="text" value={text} onChange={onChangeText} />
            <button onClick={handleUpdateUserAttribute}>変更</button>
            <input type="text" value={code} onChange={onChangeCode} />
            <button onClick={handleConfirmUserAttribute}>確認</button>
            <p>mail: {props.email}</p>

        </>
    )
}